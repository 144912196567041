import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import HomePage from "./Pages/Home";
import About from "./Pages/About";
import Contact from "Pages/Contact";
// import Store from "Pages/Store";
// import Cart from "Pages/Cart";
// import Checkout from "Pages/Checkout";
// import Orders from "Pages/Orders";
import { store, persistor } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux'
import FAQs from "Pages/FAQs";
// import Login from "Pages/Login";
// import SignUp from "Pages/SignUp";
// import ForgotPassword from "Pages/ForgotPassword";
// import Protected from "Components/Protected";
// import AccountActivation from "Pages/AccountActivation";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  // {
  //   path: "/store",
  //   element: <Store />,
  // },
  // {
  //   path: "/cart",
  //   element: <Cart />,
  // },
  // {
  //   path: "/checkout",
  //   element: <Protected><Checkout /></Protected>,
  // },
  {
    path: "/faqs",
    element: <FAQs />,
  },
  // {
  //   path: "/orders",
  //   element: <Orders />,
  // },
  // {
  //   path: "/login",
  //   element: <Login />,
  // },
  // {
  //   path: "/signup",
  //   element: <SignUp />,
  // },
  // {
  //   path: "/forgotpassword",
  //   element: <ForgotPassword />,
  // },
  // {
  //   path: "/activation",
  //   element: <AccountActivation />,
  // },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>

    </Provider>

  </React.StrictMode>
);
