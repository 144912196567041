/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Logo from "../Assets/Logo.svg";
import { BsCart2 } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import LoginIcon from '@mui/icons-material/Login';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
// import SettingsIcon from '@mui/icons-material/Settings';
// import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import QuizIcon from '@mui/icons-material/Quiz';
// import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import StoreIcon from '@mui/icons-material/Store';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//import { styled, alpha } from '@mui/material/styles';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import InputBase from '@mui/material/InputBase';
// import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
// import MailIcon from '@mui/icons-material/Mail';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import MoreIcon from '@mui/icons-material/MoreVert';
// import { auth } from '../firebase/config.js';
// import { signOut } from "firebase/auth";
// import { logout } from '../redux/userSlice';

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  const { amount } = useSelector((store) => store.cart);
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState(null);
  const currentUser = null;
 

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const logOut = () => {
    console.log('logOut');
    // signOut(auth).then(() => {
    //   dispatch(logout());
    //   handleMenuClose();
    //   navigate('/');
    //   console.log(user);
    // }).catch((error) => {
    //   console.log({ error });
    //   // An error happened.
    // });
  }
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={logOut}>Logout</MenuItem>
    </Menu>
  );
 const isLoggedIn = false //Boolean(currentUser && Object.keys(user).length != 0)
  const menuOptions = [
    {
      text: "Home",
      icon: <HomeIcon />,
      url: '/'
    },
    {
      text: "About",
      icon: <InfoIcon />,
      url: '/about'
    },
    {
      text: "FAQs",
      icon: <QuizIcon />,
      url: '/faqs'
    },
    {
      text: "Contact",
      icon: <PhoneRoundedIcon />,
      url: '/contact'
    },
    // {
    //   text: "Cart",
    //   icon: <ShoppingCartRoundedIcon />,
    //   url: '/cart'
    // },
    {
      text: "Store",
      icon: <StoreIcon />,
      url: '/store'
    },
    // !isLoggedIn && {
    //   text: "Login",
    //   icon: <LoginIcon />,
    //   url: '/login'
    // },
    // !isLoggedIn === 0 && {
    //   text: "Sign Up",
    //   icon: <HowToRegIcon />,
    //   url: '/signup'
    // },
    isLoggedIn && {
      text: "Profile",
      icon: <StoreIcon />,
      url: '/profile'
    },
    isLoggedIn && {
      text: "Account",
      icon: <AccountCircleIcon />,
      url: '/account'
    }
  ];
  return (
    <nav>
      <div className="nav-logo-container">
        <img src={"https://res.cloudinary.com/deuo4szkv/image/upload/v1708846899/ma_veg_gq41l8.png"} style={{ width: '200px' }} alt="" />

      </div>
      <div className="navbar-links-container">
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/contact">Contact</a>
        <a href="/faqs">FAQs</a>
        {/* <a href="/cart">

          <BsCart2 className="navbar-cart-icon" />
          <span className="badge">{amount}</span>
        </a> */}
        <button onClick={() => {  window.location.href = 'https://shop.maveg.co.za'}} className="primary-button">Store</button>
{/* 
        {!isLoggedIn? <button onClick={() => { navigate('/login') }} className="secondary-navbar-button">Sign In</button>
          : <Box sx={{ display: { xs: 'none', md: 'flex' }, position: 'absolute', right: '3rem', top: '1.5rem' }}>
            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>} */}
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton onClick={() => navigate(item.url)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
      {renderMenu}
    </nav>
  );
};

export default Navbar;
